<template>
  <section id="kontakt-zu-uns">
    '
    <pre>Texte hier sind nicht lektoriert, sondern vorläufig!</pre>
    <h1 class="space-large-p-t">Kontakt</h1>
    <div class="flex-row">
      <div class="half-width text flex-column center-center">
        <p>Kontaktiert uns gerne wenn Ihr Fragen habt oder mithelfen wollt.</p>
        <p>
          Entweder per E-Mail direkt an
          <a href="mailto:info@openstreetpay.org">info[at]openstreetpay.org</a>,
          oder hier im Kontaktformular
        </p>
      </div>
      <div class="half-width block-center">
        <div class="contact-form bgcolor-white">
          <fieldset class="form-fieldset">
            <form @submit.prevent="submitForm" class="form">
              <div class="form-row">
                <label for="name">Dein Name:</label>
                <input
                  v-model="data.name"
                  name="name"
                  id="name"
                  class="input-field"
                />
              </div>
              <div class="form-row">
                <label for="email">Deine E-Mail-Adresse:</label>
                <input
                  v-model="data.email"
                  name="email"
                  id="email"
                  type="email"
                  class="input-field"
                />
              </div>
              <div class="form-row">
                <label for="email">Deine Nachricht:</label>
                <textarea
                  v-model="data.message"
                  name="message"
                  id="message"
                  class="input-field"
                  rows="10"
                />
              </div>
              <div class="form-row">
                <button class="button" type="submit">Abschicken</button>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "page-contact",
  data() {
    return {
      data: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      alert("Currently not inplemented");
    },
  },
};
</script>

<style lang="scss">
@import "../styles/00-definitions";

.contact-form {
  box-shadow: $shadow-xl;
  border-radius: 30px 50px 50px 50px;
}

/* same styling as button.vue */

.button {
  transition: all 200ms;
  display: block;
  background: $color-white;
  border: 1.5px solid $color-black;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.7rem $space-large;
  -webkit-user-select: none;
  -webkit-user-select: none;
  font-weight: bold;
  font-family: Raleway-Bold;
  font-size: 0.8rem;
  line-height: 220%;
  letter-spacing: 1px;

  &.button-animated {
    border: 0;
    outline: 0;
    position: relative;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 200ms ease-out;
    &:after,
    &:before {
      content: " ";
      width: 10px;
      height: 10px;
      position: absolute;
      border: 0px solid transparent;
      transition: all ease-out 200ms;
    }
    &:after {
      top: -1px;
      left: -1px;
      border-top: 5px solid $color-blue;
      border-left: 5px solid $color-blue;
    }
    &:before {
      bottom: -1px;
      right: -1px;
      border-bottom: 5px solid $color-blue;
      border-right: 5px solid $color-blue;
    }
    &:hover {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      &:before,
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.button:hover {
    text-decoration: none;
    background-color: $color-green;
    color: $color-white;
  }
  & a {
    text-decoration: none;
  }
  & a:link {
    color: $color-black;
  }
  & a:visited {
    color: $color-black;
  }
  & a:hover {
    color: $color-green;
  }
  & a:active {
    color: $color-green;
  }
}

.form-fieldset {
  border: none;
  padding-top: $space-large;
  padding-bottom: $space-large;

  .form {
    .form-row {
      padding: $space-small $space-large;
      width: 100%;
      position: relative;
      font-family: DMSans;
      & > label {
        width: 95%;
        display: block;
        position: relative;
        left: $space-small;
        top: 4px;
        background: #fff;
      }
      & > .input-field {
        display: block;
        width: 90%;
        padding: $space-medium;
        font-family: DMSans;
      }
    }
  }
}
</style>
